import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import Contact from 'views/contact';
import MainLayout from '../layouts/MainLayout';
import NotFound from '../views/NotFound';
import Products from 'views/products';

const LandingPage = React.lazy(() => import('../views/landing'));
const CareerComponent = React.lazy(() => import('../views/careers'));
const CareerDetailComponent = React.lazy(() => import('../views/careerDetail'));

export default function Router() {
  let element = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <LandingPage />,
        },
        {
          path: '/career',
          children: [
            {
              path: '/career/:slug',
              element: <CareerDetailComponent />,
            },
            {
              path: '/career',
              element: <CareerComponent />,
            },
          ],
        },
        {
          path: '/contact',
          element: <Contact />,
        },
        {
          path: '/products',
          element: <Products />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ]);

  return element;
}
