import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

type Props = {};

const MainLayout = (props: Props) => {
    const Loader = (
      <div className="container d-flex justify-content-center align-items-center">
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
    return (
      <div className="container-fluid">
        <Header />
        <React.Suspense fallback={Loader}>
          <Outlet />
        </React.Suspense>
        <Footer />
      </div>
    );
};

export default MainLayout;
