import React from 'react';
import '../sass/components/CircularLoading.scss'

interface Props extends React.PropsWithChildren {}

const CircularLoading = (props: Props) => {
  return (
    <div className="loadingio-spinner-rolling-t4pwvx0hj2e">
        <div className="ldio-mnixogz3m2j">
            <div></div>
        </div>
    </div>
  );
};

export default CircularLoading;
