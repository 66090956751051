import CircularLoading from 'components/CircularLoading';
import React, { Fragment, useRef, useState } from 'react';
import { emailsender } from 'utils/email';

const ContactForm = () => {
  const form = useRef<HTMLFormElement>(null);

  const [emailSending, setEmailSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);

  const sendEmail: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setEmailSending(true)
    emailsender.sendForm(form, process.env.REACT_APP_EMAIL_JS_CONTACT_FORM_TEMPLATE_ID || '')
    .then(res => {
      form.current?.reset();
      setEmailSending(false)
      setEmailSent(true)
    })
    .catch(err => {
      setEmailSending(false)
      setEmailFailed(true)
    });
  };

  const inquiryOptions = [
    'Data Science and AI Consultation',
    'System Security Consultation',
    'Career Positions',
    'Partnership',
    'Project Request',
  ];

  const [serviceEnabled, setServiceEnabled] = useState(false);

  const changeService = () => {
    setServiceEnabled(true)
  }
    

  return (
    <form
      ref={form}
      className="contact-page-form my-md-2"
      onSubmit={sendEmail}
    >
      <div className="col-12 mb-3">
        <label>
          Whether you're interested in partnering with us, would like to work for us, or just want to say hi, please drop us a note below or <a className='mail-link' href='mailto:contact@logicurv.tech'>send us an email.</a>
        </label>
      </div>
      <div className="col-12 mb-3">
        <label htmlFor="fullname" className="form-label">
          Full Name
        </label>
        <input
          placeholder='John Doe'
          type="text"
          className="form-control"
          id="fullname"
          name="fullname"
          required
        />
      </div>
      <div className="col-12 mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          required
        />
      </div>
      <div className="col-12 mb-3">
        <label htmlFor="category" className="form-label text-muted">
          What kind of service we can help you with?
        </label>
        <select
          id="category"
          name="category"
          className="select form-select"
          aria-label="Default select example"
          onChange={changeService}
          style={{color: (serviceEnabled)? 'black' : '#bbb'}}
          required
          >
          <option value="" disabled selected>Choose service</option>
          {inquiryOptions.map((item, index) => (
            <option value={item} key={index}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className="col-12 mb-3">
        <label htmlFor="message" className="form-label">
          Tell us something about the project?
        </label>
        <textarea
          className="form-control"
          rows={5}
          id="message"
          name="message"
          required></textarea>
      </div>

      <div className="col-12 mt-4 pt-2">
        <div className='mb-3 notification d-flex justify-content-start align-items-center'>
            {(emailSent)? <p style={{textAlign: 'start'}}>Thank you for contacting us. We will reach out to you as soon as possible.</p> :
            (emailFailed)? <p style={{color: "red"}}>Failed to send email. Try again.</p> :
            <Fragment />
            }
        </div>
        <div className='d-flex'>
          <button
            type="submit"
            className="form-submit d-flex align-items-center me-3">
            Submit
          </button>
          {(emailSending)? <CircularLoading /> : <Fragment />}
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
