import React from 'react';

interface Props extends React.PropsWithChildren {}

const Title = (props: Props) => {
  return <h1 
    className="lead-title"
    style={{textAlign: 'center'}}
  >
      {props.children} 
  </h1>;
};

export default Title;
