export const navs = [
  {
    path: '/#home',
    name: 'Home',
  },
  {
    path: '/#services',
    name: 'Services',
  },
  {
    path: '/#about',
    name: 'About',
  },
  {
    path: '/products',
    name: 'Products',
  },
  {
    path: '/career',
    name: 'Career',
  },
  
];