type Props = {
  socials: SocialIcon[];
  inverseColor?: boolean;
};

interface SocialIcon {
  icon: string;
  name: string;
  link: string;
}
const socials = [
  {
    icon: 'bi bi-facebook',
    name: 'Facebook',
    link: 'https://facebook.com/logicurv',
  },
  {
    icon: 'bi bi-linkedin',
    name: 'LinkedIn',
    link: 'https://linkedin.com/company/logicurv-tech',
  },
  {
    icon: 'bi bi-github',
    name: 'Github',
    link: 'https://github.com/Logicurv',
  },
  {
    icon: 'bi bi-twitter',
    name: 'Twitter',
    link: 'https://twitter.com/logicurv_tech',
  },
  
];
const SocialLinks = ({ socials, inverseColor }: Props) => {
  return (
    <div className="my-2 ps-0">
      {socials.map(({ link, name, icon }) => (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          key={name}
          className={`mx-0 mx-lg-3 me-3 me-lg-3 h4 ${inverseColor ? 'text-white' : ''}`}
          role="button">
          <i style={{color: "var(--bs-link-color)"}} className={icon} aria-label={name} />
        </a>
      ))}
    </div>
  );
};

SocialLinks.defaultProps = {
  socials: socials,
};
export default SocialLinks;
