import { navs } from 'constants/navs';
import { NavigationContext } from 'contexts/navigation/NavigationContext';
import { Logo } from 'images/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { HashLink, HashLink as Link } from 'react-router-hash-link';
import { isViewInViewport } from 'utils/viewport';

type Props = {};

const Header = (props: Props) => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { active, setActive } = useContext(NavigationContext);

  const navItemsRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const handleClick = (e: MouseEvent) => {
  //     if (!navItemsRef?.current?.contains(e?.target as HTMLElement)) {
  //       setShowMobileNav(!showMobileNav);
  //     }
  //   };

  //   document.addEventListener('click', handleClick, true);

  //   return () => {
  //     document.removeEventListener('click', handleClick, true);
  //   };
  // }, []);

  useEffect(() => {
    const handleScroll = function (event: Event) {
      
      // if (window.screen.width < 992 && showMobileNav){
      //   return
      // }

      event.stopImmediatePropagation();
      const navstoCheck = navs
        .map((item) => {
          return { ...item, el: item.path.split('#')[1] };
        })
        .filter((item) => item?.el);

      navstoCheck.forEach((item) => {
        if (item && item.path.includes('#')) {
          const element = document.getElementById(item?.el);
          if (element && isViewInViewport(element)) {
            setActive(item.path);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  window.addEventListener('scroll', () => {
    let navbar = document.getElementById('navbar');
    if(navbar){
      if(window.scrollY > 0){
        navbar.style.backgroundColor = "white";
        navbar.style.boxShadow = "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px";
      }else{
        navbar.style.backgroundColor = "transparent";
        navbar.style.boxShadow = "none";
      }
    }
  })

  const navItems = navs.map((item) => (
    <HashLink
      key={item.path}
      to={item.path}
      id={`link${item.path}`}
      className={`nav-link ms-2 ${
        active.includes(item.path) ? 'active fw-bold' : ''
      }`}
      onClick={() => {
        setTimeout(() => {
          setActive(item.path);
          // setShowMobileNav(false);
        }, (showMobileNav)? 1000 : 25)
      }}
      aria-current="page">
      {item.name}
    </HashLink>
  ));
  return (
    <>
      <header>
        <nav id="navbar" className="navbar navbar-expand-lg navbar-primary">
          <div className="container-fluid app-wrapper">
            <a className="navbar-brand ms-2" href="/#home">
              <Logo fill='#255F85' />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setShowMobileNav(!showMobileNav)}
              aria-expanded="false"
              aria-label="Toggle navigation">
              {(!showMobileNav)? 
                <i className="bi bi-list" style={{fontSize: '30px'}}></i>
                  :
                <i className="bi bi-x" style={{fontSize: '30px'}}></i>
              }
            </button>
            <div className="collapse navbar-collapse">
              <div className="navbar-nav d-none d-md-flex ms-auto flex-nowrap me-4 pe-4">
                {navItems}
                <Link 
                  to={'/contact'}
                  onClick={() => {
                    setTimeout(() => {
                      setActive('/contact');
                    }, 25)
                  }}
                >
                  <button className='nav-button'>Get in touch</button>
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div
        data-mdb-toggle="animation"
        data-mdb-animation-reset="true"
        data-mdb-animation="slide-out-right"
        onClick={() => setShowMobileNav(false)}
        className={`navbar-mobile  ${
          showMobileNav ? 'show  d-lg-none' : 'hide'
        }`}>
        <div className={`navbar-nav ${showMobileNav ? 'one' : 'zero'} ms-auto px-4`} ref={navItemsRef}>
          {navItems}
          <Link 
            to={'/contact'}
            onClick={() => {
              setTimeout(() => {
                setActive('/contact');
              }, 25)
            }}
          >
            <button className='nav-button'>Get in touch</button>
          </Link>
          {/* <span
            className="close-btn d-block mt-auto"
            onClick={() => {
              setShowMobileNav(!showMobileNav);
            }}>
            <span className="me-2">Close</span>
            <i className="bi bi-box-arrow-right"></i>
          </span> */}
        </div>
      </div>
    </>
  );
};

export default Header;
