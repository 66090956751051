import React, { PropsWithChildren } from 'react';
import { NavigationContextProvider } from './navigation/NavigationContext';

interface Props extends PropsWithChildren {}

const AppContext = (props: Props) => {
  return (
    <NavigationContextProvider>{props.children}</NavigationContextProvider>
  );
};

export default AppContext;
