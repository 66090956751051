import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="section clear-header container-fluid d-flex align-items-center justify-content-center">
      <div>
        <h2>Nothing to see here!</h2>
        <p>
          <Link to="/#home">Go to the home page</Link>
        </p>
      </div>
    </div>
  );
}

export default NotFound;
