import { useState, useEffect } from 'react';

interface Iprops {
  title: string;
  detail: string;
  onClick?: () => void;
  isSelected?: boolean;
}

const ProductCard = (props: Iprops) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (props?.isSelected) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [props?.isSelected]);

  const { title, detail, onClick } = props;

  return (
    <div
      className={`product-card cursor-pointer`}
      style={{
        border: isSelected ? '1px solid #E37429' : 'none',
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}>
      <p className={'card-header'}>{title}</p>
      <p className={'card-detail'}>{detail}</p>
    </div>
  );
};

export default ProductCard;
