import { NavigationContext } from 'contexts/navigation/NavigationContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import JoinImage from '../../images/backgrounds/JoinUs.svg';
import './join.scss';

const Join = () => {
  const { setActive } = useContext(NavigationContext);
  return (
    <section id="join" className="join pt-5 pb-0" style={{backgroundColor: '#154A62', color: 'white'}}>
      <div className="app-wrapper d-flex flex-column flex-lg-row align-items-center" style={{fontFamily: "Inter"}}>
        <div className="join-all container-fluid d-flex flex-column align-items-start">
          <h1>
            Want to join us?
          </h1>
          {/* <img src={InfoImage} alt={'info'} /> */}
          <p className='me-lg-4 mt-4' style={{fontSize: 20, fontWeight: 400, textAlign: 'justify'}}>
          We believe that challenges are best solved with a team. A team you trust is even better. Let's work together with trust and transparency to achieve your next goal. Check out the vacancies on our <Link to="/career" style={{color: '#6CE9A6'}} onClick={() => setActive('/career')}>careers page</Link> to find out more.
          </p>
        </div>
        <img className='image ms-lg-4 mt-5 mt-lg-0' src={JoinImage} alt='joinus' />
      </div>
    </section>
  );
};

export default Join;
