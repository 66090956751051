import AppContext from 'contexts';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';

function App() {
  return (
    <AppContext>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </AppContext>
  );
}

export default App;
