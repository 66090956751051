type Props = {
  inverseColor?: boolean;
};
const CompanyDetail = ({ inverseColor }: Props) => {
  return (
    <div className="contact-info text-center text-md-start">
      <p>Bhanimandal, Ward-4, Lalitpur, 44700, Nepal</p>
      <p>
        <a
          href="mailto:contact@logicurv.tech"
          className={`text-decoration-none ${
            inverseColor ? 'text-white' : 'text-black'
          }`}>
          contact@logicurv.tech
        </a>
      </p>
      
    </div>
  );
};

export default CompanyDetail;
