import { useState, useCallback, lazy } from 'react';
import { Link } from 'react-router-dom';
import GooglePlay from '../../images/icons/google-play.svg';
import AppStore from '../../images/icons/app-store.svg';
import ProductCard from './components/ProductCard';
import './products.scss';
import CashlessImage from 'images/products/first-section/cashless-payment.svg';
import EasyUseImage from 'images/products/first-section/easy-to-use.svg';
import GetToKnowImage from 'images/products/first-section/get-to-know.svg';
import RentingMadeEasyImage from 'images/products/first-section/renting-made-easy.svg';

import GetPaidImage from 'images/products/second-section/get-paid.svg';
import ListPropertyImage from 'images/products/second-section/list-property.svg';
import WelcomeGuestImage from 'images/products/second-section/welcome-guest.svg';

import BookPropertyImage from 'images/products/third-section/book-property.svg';
import SearchPropertyImage from 'images/products/third-section/search-property.svg';
import GoImage from 'images/products/third-section/go.svg';
import DoubleHome from 'images/products/double-screen.svg';
import BannerImage from 'images/products/banner-image.svg';
import BannerImageHigher from 'images/products/banner-image-higher-resolution.svg';

enum FirstSection {
  'easy_to_use',
  'cashless_payment',
  'renting_made_easy',
  'get_to_know_ur_guest',
}

enum SecondSection {
  'list_property',
  'welcome_guest',
  'get_paid',
}

enum ThirdSection {
  'search',
  'book',
  'go',
}

const firstSectionCardContent = [
  {
    title: 'Easy to use',
    detail:
      'With our user-friendly interface, both finding your dream holiday accommodation or renting a spare space, both has never been easier',
    value: FirstSection.easy_to_use,
  },
  {
    title: 'Cashless Payment',
    detail:
      'Say goodbye to cash, use e-sewa and khalti to book you next stay upfront so you can enjoy your holiday in peace.',
    value: FirstSection.cashless_payment,
  },
  {
    title: 'Renting made easy',
    detail:
      'Everything you need to list your property is in one place. Write a description, choose your settings, add photos and set your price — we’ll guide you step by step',
    value: FirstSection.renting_made_easy,
  },
  {
    title: 'Get to know your guest/host',
    detail:
      'With our in-app messaging, your guest/host is no more stranger. Get to know your guests before they come by using our in-app messaging service.',
    value: FirstSection.get_to_know_ur_guest,
  },
];

const secondSectionCardContent = [
  {
    title: '1. List your property',
    detail:
      'Add your property with photos, description, amenities, rules, policies, and set your price.',
    value: SecondSection.list_property,
  },
  {
    title: '2. Welcome guests',
    detail: 'Accept the booking request and welcome your first guest.',
    value: SecondSection.welcome_guest,
  },
  {
    title: '3. Get paid',
    detail: 'Get paid       ',
    value: SecondSection.get_paid,
  },
];

const thirdSectionCardContent = [
  {
    title: 'Search',
    detail:
      'With Basiyo, finding your dream vacation rental is a breeze. Simply filter your search based on location, dates, number of guests, and desired amenities, and let our powerful search engine do the rest',
    value: ThirdSection.search,
  },
  {
    title: 'Book',
    detail:
      'Booking through our app is seamless and secure, offering you peace of mind throughout the entire process. Detailed property descriptions, high-quality images, house rules, cancellation policy, and guest reviews give you an accurate representation of each units, empowering you to make an informed decision',
    value: ThirdSection.book,
  },
  {
    title: 'Go',
    detail:
      'Whether it’s a family vacation, a romantic getaway, or a group adventure, Basiyo is there to take care of you. Start your journey and unlock the door to unforgettable experiences and cherished memories.',
    value: ThirdSection.go,
  },
];

const Products = () => {
  const [firstSectionSelectedCardItem, setFirstSectionSelectedCardItem] =
    useState<FirstSection>(FirstSection.easy_to_use);
  const [secondSectionSelectedCardItem, setSecondSectionSelectedCardItem] =
    useState<SecondSection>(SecondSection.list_property);
  const [thirdSectionSelectedCardItem, setThirdSectionSelectedCardItem] =
    useState<ThirdSection>(ThirdSection.search);

  const firstSectionImage = useCallback(() => {
    switch (firstSectionSelectedCardItem) {
      case FirstSection.easy_to_use:
        return EasyUseImage;
      case FirstSection.cashless_payment:
        return CashlessImage;
      case FirstSection.renting_made_easy:
        return RentingMadeEasyImage;
      case FirstSection.get_to_know_ur_guest:
        return GetToKnowImage;
      default:
        return null;
    }
  }, [firstSectionSelectedCardItem]);

  const secondSectionImage = useCallback(() => {
    switch (secondSectionSelectedCardItem) {
      case SecondSection.list_property:
        return ListPropertyImage;
      case SecondSection.welcome_guest:
        return WelcomeGuestImage;
      case SecondSection.get_paid:
        return GetPaidImage;
      default:
        return null;
    }
  }, [secondSectionSelectedCardItem]);

  const thirdSectionImage = useCallback(() => {
    switch (thirdSectionSelectedCardItem) {
      case ThirdSection.search:
        return SearchPropertyImage;
      case ThirdSection.book:
        return BookPropertyImage;
      case ThirdSection.go:
        return GoImage;
      default:
        return null;
    }
  }, [thirdSectionSelectedCardItem]);

  return (
    <div className={'product-page-main-container'}>
      <section className="banner d-flex align-items-center product-container">
        <div className="app-wrapper">
          <div className="container">
            <p className={'logicurv-product'}></p>
            <h3 className={'product-header'}>
              Basiyo is the new standard for holiday rentals in Nepal
            </h3>
            <p className="product-details">
              Basiyo is an excellent rental platform for host that allow to make
              some extra money renting out their spare home or room to people
              visiting the area. And for guests, Basiyo can offer a homey place
              to stay that has more character at a lower price.
            </p>
            <div className={'link-container'}>
              <Link to={'https://basiyo.com/'} target={'_blank'}>
                <button className="banner-button">
                  Get started <i className="bi bi-arrow-right arrow-icons"></i>
                </button>
              </Link>
            </div>
            <div className={'banner-image-container-web'}>
              <div className={'banner-image-container-web-inner'}>
                <img
                  className="banner-image-web"
                  src={BannerImageHigher}
                  alt="banner-image"
                  loading={'lazy'}
                />
              </div>
            </div>

            <div className={'banner-image-container-mobile'}>
              <img
                className="banner-image"
                src={BannerImage}
                alt="banner-image"
                loading={'lazy'}
              />
            </div>
          </div>
        </div>
      </section>
      <div className={'first-section'}>
        <div className={'d-flex justify-content-center align-items-center'}>
          <p className={'first-section-heading'}>
            Use a digital system to benefit your guests and your business?
          </p>
        </div>
        <div className={'d-flex justify-content-center align-items-center'}>
          <div className={'central-bar'}></div>
        </div>
        <div className={'container first-section-container'}>
          <img
            className="first-section-image"
            src={firstSectionImage()!}
            loading={'lazy'}
            alt="app-store"
          />
          <div className={'card-container'}>
            {firstSectionCardContent?.map((item) => {
              return (
                <ProductCard
                  title={item?.title}
                  detail={item?.detail}
                  onClick={() => setFirstSectionSelectedCardItem(item?.value)}
                  isSelected={item.value === firstSectionSelectedCardItem}
                  key={item?.value}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={'second-section'}>
        <div className={'d-flex justify-content-center align-items-center'}>
          <p className={'second-section-heading'}>
            Rent your space in 3 easy steps
          </p>
        </div>
        <div className={'d-flex justify-content-center align-items-center'}>
          <div className={'central-bar'}></div>
        </div>
        <div className={'container second-section-container'}>
          <div className={'card-container'}>
            {secondSectionCardContent?.map((item) => {
              return (
                <ProductCard
                  title={item?.title}
                  detail={item?.detail}
                  onClick={() => setSecondSectionSelectedCardItem(item?.value)}
                  isSelected={item.value === secondSectionSelectedCardItem}
                  key={item?.value}
                />
              );
            })}
          </div>
          <img
            className="second-section-image"
            src={secondSectionImage()!}
            loading={'lazy'}
            alt="second-section-image"
          />
        </div>
      </div>
      <div className={'third-section'}>
        <div
          className={
            'd-flex flex-column justify-content-center align-items-center'
          }>
          <p className={'third-section-heading'}>
            Need a place to stay? Basiyo can help
          </p>
          <p className={'text-center section-detail'}>
            From homestay to lodges, resorts or hotels to the apartments in
            bustling cities, Basiyo have something to suit every traveler's
            taste and budget. Your dream holiday is just three steps away.
          </p>
        </div>
        <div className={'d-flex justify-content-center align-items-center'}>
          <div className={'central-bar'}></div>
        </div>
        <div className={'container third-section-container '}>
          <img
            className="third-section-image"
            loading={'lazy'}
            src={thirdSectionImage()!}
            alt="app-store"
          />
          <div className={'card-container'}>
            {thirdSectionCardContent?.map((item) => {
              return (
                <ProductCard
                  title={item?.title}
                  detail={item?.detail}
                  onClick={() => setThirdSectionSelectedCardItem(item?.value)}
                  isSelected={item.value === thirdSectionSelectedCardItem}
                  key={item?.value}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={'product-banner'}>
        <div
          className={
            'd-flex justify-content-center flex-column align-items-center`'
          }>
          <h1 className={'banner-heading'}>
            Want to know more about <br /> Basiyo ?
          </h1>
          <div className={'image-container'}>
            <Link
              to={
                'https://play.google.com/store/apps/details?id=com.basiyo.basiyo&pcampaignid=web_share'
              }
              target="_blank">
              <img className="google-play" src={GooglePlay} alt="google-play" />
            </Link>
            <Link
              to={'https://apps.apple.com/us/app/basiyo/id6502579096?uo=2'}
              target="_blank">
              <img className="app-store" src={AppStore} alt="app-store" />
            </Link>
          </div>
        </div>
        <div className={'double-screen-container'}>
          <img
            className="double-image"
            src={DoubleHome}
            alt="double-image"
            loading={'lazy'}
          />
        </div>
      </div>
    </div>
  );
};

export default Products;
