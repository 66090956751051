import { Logo } from 'images/icons';
import CompanyDetail from 'views/contact/components/CompanyDetail';
import { navs } from 'constants/navs';
import SocialLinks from './SocialLinks';
import { NavigationContext } from 'contexts/navigation/NavigationContext';
import { useContext } from 'react';
import { HashLink } from 'react-router-hash-link';

type Props = {};

const Footer = (props: Props) => {
  const { setActive } = useContext(NavigationContext);
  return (
    <footer className="pt-4 footer d-flex justify-content-center">
      <div className="container-fluid app-wrapper p-0 m-0 pt-4 mt-4 px-4">
        <section className="footer-top">
          <div className="d-flex flex-md-row flex-column justify-content-center align-items-md-start align-items-center">
            <div className="footer-info d-flex flex-column align-items-md-start align-items-center">
              <a className="text-decoration-none mb-4" href="/#home">
                <Logo width={175} height={52} />
              </a>
              <CompanyDetail />
              <div className="mt-4 footer-social">
                <SocialLinks />
              </div>
            </div>

            <div className="col mt-5 mt-md-0">
              <div className="d-flex flex-md-row flex-column">
                {navs.map((item, index) => (
                  // <p key={item.path} className="col text-center my-4 my-lg-0">
                  <HashLink
                    key={index}
                    to={item.path}
                    className="text-decoration-none col text-center my-4 my-md-0 mx-2"
                    style={{ fontWeight: 600, fontSize: 18, color: '#293643' }}
                    onClick={() => {
                      setTimeout(() => {
                        setActive(item.path);
                      }, 100);
                    }}>
                    {item.name}
                  </HashLink>
                  // </p>
                ))}
              </div>
            </div>
          </div>
        </section>
        <hr className="mt-3" />

        <section className="mb-4 pt-0 pb-0 px-0">
            <div className="col-md-12 py-2">
              <p className='text-center text-md-start ' style={{color: '#667085', fontSize: 14, fontWeight: 400}}>
                © {new Date().getFullYear()} Logicurv Technologies Pvt. Ltd. All rights reserved.
              </p>
            </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
