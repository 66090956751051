import React, { PropsWithChildren, useState } from 'react';

const NavigationContext = React.createContext<{
  active: string;
  setActive: (active: string) => void;
}>({ active: '', setActive: () => {} });

const NavigationContextProvider = (props: PropsWithChildren) => {
  const location = window.location;
  const activehash =
    location.hash || location.pathname !== '/'
      ? location.pathname + location.hash
      : '/#home';
  const [active, setActive] = useState(activehash);

  return (
    <NavigationContext.Provider value={{ active, setActive }}>
      {props.children}
    </NavigationContext.Provider>
  );
};

export { NavigationContextProvider, NavigationContext };
