import emailjs from '@emailjs/browser';
import React from 'react';

class EmailSender {
  serviceid: string;
  publickey: string;

  constructor() {
    this.serviceid = process.env.REACT_APP_EMAIL_JS_SERVICE_ID || '';
    this.publickey = process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY || '';
    emailjs.init(this.publickey);
  }

  sendForm(form: React.RefObject<HTMLFormElement>, templateid: string) {
    const htmlform = form.current as HTMLFormElement;
    return emailjs.sendForm(
      this.serviceid,
      templateid,
      htmlform,
      this.publickey,
    );
  }
}

export const emailsender = new EmailSender();
