import ContactForm from './components/ContactForm';
import Title from 'components/Title';
import SocialLinks from 'components/SocialLinks';
import Join from 'views/join';
import { Fragment, useLayoutEffect } from 'react';

type Props = {};

const Contact = (props: Props) => {
  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 25)
  }, []);

  return (
    <Fragment>
      <section id="contact" className="contact-detail pt-4 pb-5">
        <div className="container-fluid contact-container mb-5">
          <div className='d-flex flex-column'>
            <p>CONTACT US</p>
            <Title>Let's connect</Title>
          </div>
          <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-between mt-5">
            <div className='form-container'>
              <ContactForm />
            </div>
            <div className="company-details d-flex flex-column justify-content-start align-items-start mt-5 pt-4 pt-md-1 mt-md-0">
              <div className='col mb-4'>
                <h1>The office</h1>
                <p>Bhanimandal, Ward-4, Lalitpur, 44700, Nepal</p>
              </div>
              <div className='col mb-4'>
                <h1>Say hello</h1>
                <p><a className='company-mail' href='mailto:contact@logicurv.tech'>contact@logicurv.tech</a></p>
              </div>
              <div className='col'>
                <h1>Follow us on</h1>
                <div className='contact-social'>
                  <SocialLinks />
                </div>
              </div>
            </div>
            {/* <LocationMap /> */}
          </div>
        </div>
      </section>
      <Join />
    </Fragment>
  );
};

export default Contact;
